import axios from 'axios'
import { Message } from 'element-ui'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
import Router from '../router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// // request interceptor
// service.interceptors.request.use(
//   config => {
//     // do something before request is sent

//     if (store.getters.token) {
//       // let each request carry token
//       // ['X-Token'] is a custom headers key
//       // please modify it according to the actual situation
//       config.headers['X-Token'] = getToken()
//     }
//     return config
//   },
//   error => {
//     // do something with request error
//     console.log(error) // for debug
//     return Promise.reject(error)
//   }
// )

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const { data } = response

    switch (data.code) {
      case 400:
        Message.error(data.message)
        return Promise.reject(new Error(data.message))
      case 401:
        Message.warning('登录状态失效,请重新登录')
        // eslint-disable-next-line no-case-declarations
        const origin = window.localStorage.getItem('origin')
        if (typeof origin === 'undefined' || origin == null || origin === '') {
          Router.push({ path: '/401' })
        } else {
          window.location.href = origin
        }
        return Promise.reject(new Error(data.message))
      case 403:
        Message.error(data.message)
        return Promise.reject(new Error(data.message))
      case 404:
        Message.error(data.message)
        return Promise.reject(new Error(data.message))
      case 200:
        // Message.success(data.message)
        return data.data
      case 201:
        Message.success('创建成功')
        return data.data
      default:
        Message.error(data.message)
        return data.data
    }
  },
  error => {
    console.log(error)
  }
)

export default service
